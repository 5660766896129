/* @import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;200;300;400;500;600;700&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 300;
  font-size: 14px;
}

.listItem:hover {
  background: #e2e8f0;
  cursor: pointer;
}

.github-corner svg {
  position:absolute;
  right:0;
  top:0;
  mix-blend-mode:darken;
  color:#ffffff;
  fill:#000000;
}
.github-corner:hover .octo-arm {
  animation:octocat-wave .56s;
}
@keyframes octocat-wave {
  0%, 100% {
    transform:rotate(0);
  }
  20%, 60% { 
    transform:rotate(-20deg);
  
  } 40%, 80% {
    transform:rotate(10deg);
  }
} */


.navbar {
  position: relative;
  z-index: 100;
}

.main-content {
  position: relative;
  z-index: 1;
}
.row_custom{
  margin-left: 0px!important;
  margin-right: 0px!important;
}


.container-right-block-top {
  display: flex;
  flex-direction: column;
  height: 10vh; /* Set the height of the container as needed */
}
.container-right-block-output{
  display: flex;
  flex-direction: column;
  height: 20vh; 
}

.div1, .output-div {
  flex: 1;
}

.output-div {
  flex: 1;
  background-color: #292931;
  color: white;
}
.output-div .text-green-500{
  background-color: #292931;
  color: rgb(180 245 58);
  font-size: initial;
}
.custom-width-play-pause-button {
  width: 54px; /* Set your desired default width here */
}

@media (max-width: 768px) {
  .custom-width-play-pause-button {
    width: 42px; /* Adjust the width for smaller screens */
  }
}