
html, body {
  height: 100%;
  margin: 0;
  background-color: #212529;
  color: white;;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.top-bar{
  height: 5vh;
}


/* FOR VIDEO PLAYER */
/* Progress Bar */
.progress-bar {
  width: 100%;
  height: 10px;
  background-color: #ccc;
  margin-bottom: 10px;
}

.progress {
  height: 100%;
  background-color: #ff0000; /* Adjust color as needed */
}

/* Tags */
.tags {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}

.tag {
  display: inline-block;
  padding: 5px;
  margin-right: 10px;
  background-color: #f2f2f2;
  border-radius: 4px;
  cursor: pointer;
}

.tag.active {
  background-color: #ff0000; /* Adjust color as needed */
  color: #fff;
}


/* Progress Bar */
.external-progress-bar {
  /* position: relative;
  width: 100%;
  height: 10px;
  background-color: #ccc;
  margin-bottom: 10px; */
  margin-left: 10px; 
  width: 100%;
  position: relative;
}


.progress {
  width: 100%;
  height: 8px;
  background-color: #5e5a5a;
  border-radius: 4px;
  appearance: none;
  outline: none;
  margin-top: 10px;
}

.progress::-webkit-slider-thumb {
  appearance: none;
  width: 16px;
  height: 16px;
  background-color: #333;
  border-radius: 50%;
  cursor: pointer;
}
/* Flags */
.flag {
  position: absolute;
  top: -10px;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px; /* Adjust the border-width value as needed */
  border-color: transparent transparent #0c76a0 transparent;
  transform: translateX(-50%);
  cursor: pointer;
}






 



  .custom-select {
    width: 200px; /* Set the desired width */
    height: 30px; /* Set the desired height */
    font-size: 16px; /* Set the desired font size */
    /* Add any other desired styles */
    margin-right: 2vh;
  }

  .landing-control{
    background-color: #b5babd; /* Set the background color */
    color: #050101; /* Set the text color */
    height: 60px; /* Set the height of the navigation bar */
    display: flex; /* Use flexbox for positioning */
    align-items: center; /* Center content vertically */
    justify-content: space-between; /* Spread content horizontally */
    padding: 20px; /* Add padding around content */
  }

   /* Style for the menu options container */
   .landing-control .menu-options {
    display: flex; /* Use flexbox for horizontal layout */
  }
  
  /* Style for the navigation links */
  .landing-control .menu-options a {
    color: #050101; /* Set the text color */
    text-decoration: none; /* Remove underline */
    margin-left: 20px; /* Add spacing between links */
  }
  
  /* Style for the active link */
  .landing-control .menu-options a.active {
    background-color: #000000; /* Set a different background color for the active link */
  }

  .container_play_progress {
    display: flex;
  }


  .ml-auto {
    margin-left: auto;
  }


  .content {
    height: calc(100% - 510px)!important; /* Calculate the remaining height */
  }

  .monaco-editor {
    padding-top: 15px;
   }